import Button from '../../../../common/Button/Button.tsx';
import PlaceholderIcon from '../../../../../assets/insufficient-permission.svg?react';
import Redirect from '../../../../../assets/redirect.svg?react';
import Card from '../../../../common/Card/Card.tsx';
import EmptyPlaceholder from '../../../../shared/empty-placeholder/EmptyPlaceholder.tsx';

import styles from './InsufficientPageCard.module.css';

function InsufficientPermissionPage() {
  return (
    <Card className={styles.InsufficientPageCard}>
      <EmptyPlaceholder
        placeholderIcon={<PlaceholderIcon />}
        placeholderHeading="Insufficient permissions"
        height="100%"
        placeholderButton={
          <Button
            variant="Tertiary"
            size="Default"
            type="button"
            ariaLabel="Learn More"
            onClick={() =>
              window.open('https://help.webex.com/en-us/article/n0jb6sdb/Registration-Hub-for-Webex-LTI', '_blank')
            }
            rightIcon={<Redirect />}
          >
            Learn more
          </Button>
        }
      >
        In order to use Webex LTI Registration Hub, <br aria-hidden="true" />
        you must be a full administrator of at least one Webex Meetings site.
      </EmptyPlaceholder>
    </Card>
  );
}

export default InsufficientPermissionPage;
