import ErrorPanel from '../../../../common/ErrorPanel/ErrorPanel.tsx';
import Flex from '../../../../common/Flex/Flex.tsx';
import Text from '../../../../common/Text/Text.tsx';
import Button from '../../../../common/Button/Button.tsx';
import { Variant } from '../../../../common/Banner/Banner.tsx';
import { Errors } from '../../../../../utils/Errors.tsx';

import Header from '../../header/Header.tsx';

import styles from './SignIn.module.css';

type SignInProps = {
  errors?: Errors;
  variant?: Variant;
};
function SignIn({ errors, variant = 'Error' }: SignInProps) {
  const onHelpButtonClicked = () => {
    window.open(
      'https://help.webex.com/en-us/article/n0jb6sdb/Webex-LTI-Registration-Hub' +
        '-for-admins#concept-template_44036f6f-f908-4a82-9504-162d0662cc1a',
      '_blank'
    );
  };
  return (
    <>
      <Header showWebexLogo showProfile={false} />
      <div className={styles.SignInCard}>
        {errors && <ErrorPanel variant={variant} errors={errors} />}
        <Flex justifyContent="center" alignItems="center" height="100%" width="100%">
          <Flex flexDirection="column" justifyContent="center" alignItems="center" rowGap="12px" maxWidth="498px">
            <Text variant="Heading" size="Large" type="Medium" className={styles.CenterText}>
              Webex LTI
            </Text>
            <Text variant="Body" size="Midsize" type="Regular" className={styles.CenterText}>
              If you are a Webex site administrator, you can access registration hub through the admin login page. To
              learn more about Webex LTI, please visit the help center.
            </Text>
            <Button
              className={styles.SignInButton}
              type="button"
              variant="Primary"
              size="Default"
              onClick={onHelpButtonClicked}
              ariaLabel="visit-the-help-center"
            >
              Visit the help center
            </Button>
          </Flex>
        </Flex>
      </div>
    </>
  );
}

export default SignIn;
