// eslint-disable-next-line max-len
import InsufficientPermissionPage from '../registration/authentication/insufficient-permission/InsufficientPermissionPage.tsx';
import SignIn from '../registration/authentication/sign-in/SignIn.tsx';
import Header from '../registration/header/Header.tsx';

function LoginPage() {
  const rootElement = document.querySelector('#react-root');
  const error = rootElement && rootElement.getAttribute('data-error');
  const errorMessage = rootElement && rootElement.getAttribute('data-error-message');

  if (error === 'invalid_scope') {
    return (
      <>
        <Header showProfile={false} />
        <InsufficientPermissionPage />
      </>
    );
  }
  if (error === 'access_denied') {
    return (
      <SignIn
        variant="Info"
        errors={['Sign in with Webex and accept the permissions requested to access Registration Hub.']}
      />
    );
  }
  if (errorMessage) {
    return <SignIn errors={errorMessage} />;
  }
  if (error === '') {
    return <SignIn errors={['Something went wrong and we couldn&apos;t sign you in. Try again later.']} />;
  }
  return <SignIn />;
}

export default LoginPage;
