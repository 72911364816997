import React from 'react';
import ReactDOM from 'react-dom/client';
import '../src/stylesheets/normalize.css';
import '../src/stylesheets/fonts.css';
import '../src/stylesheets/colors.css';
import '../src/index.css';

import LoginPage from '../src/components/app/webex_admin/LoginPage.tsx';

const reactRootEl = document.querySelector('#react-root');

if (reactRootEl) {
  ReactDOM.createRoot(reactRootEl).render(<LoginPage />);
}
